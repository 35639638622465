.StripeContainer {
  display: flex;
  height: 100vh;
  background: #f3fdff;
  flex-direction: column;
}

.pContainerStripe {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #f3fdff;
}

.StrOrderTotal {
  color: var(--00-on-surface-high-emphasis, rgba(0, 0, 0, 0.87));
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  /* margin-left: 36px; */
  margin-top: 30px;
}

.StrOrderPrice {
  color: var(--00-on-surface-high-emphasis, rgba(0, 0, 0, 0.87));
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  /* margin-left: 36px; */
  margin-top: 3px;
}

.SecureText {
  color: #9ca3af;
  font-size: 9px;
  font-weight: 700;
  margin-left: 8px;
}

.SecureBox {
  display: flex;
  align-items: center;
  margin-top: 19px;
  /* margin-left: 35px; */
}

.StrbottomBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-top: 55px;
  /* margin-left: 35px; */
}

.StrbackBtnAddress {
  width: 72px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: black;
  background: white;
  font-size: 16px;
  font-weight: 700;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.StrcontinueBtn {
  width: 300px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 700;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.StrcontinueDisableBtn {
  width: 300px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: #6f6f6f;
  background: #e1e1e1;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.StripeElement {
  height: 40px;
  padding-top: 15px;
  padding-left: 8px;
  /* padding: 10px 12px; */
  width: 100%;
  color: #32325d;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #9cd3e4;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
