.pContainerOrder {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.containerOrder {
  width: 300px;
  padding: 0px;
}

.scrollContainer {
  overflow-y: auto;
  height: auto;
  max-height: 75vh;
}

.menuLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.fastpaypriceLine {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-width: 130px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.fastpayTotalpriceLine {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fastPayGSTROW {
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin-top: 5px;
}

.fastpayitemrow {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.line2 {
  display: flex;
  flex-direction: row;
}
.title {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin: 0px;
}
.subTitle {
  font-size: 12px;
  font-weight: 700;
  color: black;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.price {
  display: flex;
  flex-direction: column;
}
.fastpaydishName {
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.fastpaydishPrice {
  margin: 0;
  margin-left: auto;
  width: 100px;
  text-align: right;
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.fastpaydishQuantity {
  margin: 0;
  position: absolute;
  margin-left: 100px;
  text-align: right;
  width: 100px;
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.fastpayCalCulationText {
  text-align: right;
  color: black;
  font-size: 14px;
  font-weight: 400;
  width: 100px;
  margin: 0;
}

.fastpayCalCulationBoldText {
  text-align: right;
  color: black;
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  margin: 0;
}

.dishDescription {
  width: 220px;
  margin: 0;
  color: black;
  font-size: 12px;
  font-weight: 700;
}

.fastpayitemContainer {
  margin: 0;
}

.bottomBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.orLabel {
  color: black;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  /* margin-top: 80px; */
}

.fastPayGSTText {
  color: #9ca3af;
  font-size: 10px;
  font-weight: 700;
  margin: 0;
}

.fastPayBusinessAddressText {
  color: black;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fastPayBusinessDateText {
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fastPayOrderTitleText {
  color: black;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
}

.fastPayOrderSubTitleText {
  color: black;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.fastPayItemText {
  text-align: right;
  color: black;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}

.fastPayGSTText1 {
  color: #9ca3af;
  font-size: 10px;
  font-weight: 700;
  margin: 0;
  width: 60px;
}
.pickupBtn {
  width: 125px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: black;
  background: white;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.deliveryBtn {
  width: 300px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 700;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
  margin-top: 100px;
}

.fastpayBottomRow {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bottomBtn1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.backBtn {
  width: 72px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: black;
  background: white;
  font-size: 16px;
  font-weight: 700;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.continueBtn {
  width: 202px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 700;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.orderPoweredBy {
  width: 290px;
  height: 52px;
  margin-top: 5px;
}
