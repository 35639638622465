.moneyContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.innerContainer {
  width: 300px;
  padding: 0px;
  display: flex;
  flex-direction: column; /* Ensure elements are stacked vertically */
  align-items: center; /* Center content horizontally */
}

.importantBox {
  border-radius: 12px;
  border: 2px solid #fedf89;
  background: #fef0c7;
  width: 290px;
  height: 104px;
  margin-top: 25px;
}

.importantText {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--warning-200, #fedf89);
  background: var(--warning-600, #dc6803);
  color: var(--base-white, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  width: 82px;
  height: 24px;
  margin-top: 16px;
  margin-left: 16px;
}

.moneybutton {
  margin-top: 12px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
