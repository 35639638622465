.kitchencontainer {
  width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  /* border: 1px solid rgba(106, 106, 106, 0.2); */
  /* min-height: 100vh; */
  padding-bottom: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tapButtonsRow {
  display: flex;
  justify-content: space-between; /* ensures even spacing */
  width: 100%; /* make sure the container takes full width */
}

.kitchentapbutton {
  color: black;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  background: #f4f4f4;
  border: none;
  padding: 8px 12px;
  margin: 4px;
  cursor: pointer;
}

.kitchentapbutton.selected {
  background-color: black;
  color: white;
}

.kitchenmenuLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.kitchenItemRow {
  width: 100%;
  padding: 0 0px;
}

.kitchenItemRowLine1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.kitchenDishName {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.kitchenDishPrice {
  margin-left: auto;
  color: black;
  text-align: right;
  font-size: 14px;
  font-weight: 700;
}

.kitchenDescription {
  color: black;
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}

.kitchenDishImage {
  width: 46.586px;
  height: 47.835px;
  flex-shrink: 0;
  border-radius: 4px;
}

.kitchenHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  padding: 0 15px;
}

.kichenNumber {
  color: black;
  font-size: 14px;
  font-weight: 700;
}

.kichenNumberView {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kitchenCallText {
  color: black;
  font-size: 12px;
  font-weight: 400;
}

.poweredbyBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white; /* background white */
  display: flex; /* To center the image */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.poweredBy {
  width: 290px;
  height: 52px;
}

.divider {
  position: fixed;
  width: 360px;
  bottom: 100px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.categoryOptionImg1 {
  width: 10px;
  height: 15px;
  margin-left: 10px;
}

.categoryOptionImg2 {
  width: 17px;
  height: 15px;
  margin-left: 10px;
}

.categoryOptionImg3 {
  width: 25px;
  height: 15px;
  margin-left: 10px;
}

.greenCircle {
  background-color: #00a52e;
  width: 9px;
  height: 9px;
  margin-left: 10px;
  border-radius: 50%;
}
